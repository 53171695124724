import { ethers } from "ethers";
import { toast } from "react-toastify";
import {
  coreStakingManagerAddress,
  softTokenAddress,
  softTokenFaucetAddress,
} from "../../config/contracts";
import CoreStakingManager from "../../contracts/CoreStakingManager.sol/CoreStakingManager.json";
import SoftTokenFaucet from "../../contracts/SoftTokenFaucet.sol/SoftTokenFaucet.json";
import ERC20 from "../../contracts/dependencies/openzeppelin/ERC20.sol/ERC20.json";
import toastUtils from "../../utils/toasts";

class CoreStakingManagerService {
  constructor(provider) {
    this.provider = provider;
    this.contract = new ethers.Contract(
      coreStakingManagerAddress,
      CoreStakingManager.abi,
      provider.getSigner()
    );
    console.log("softTokenAddress:", softTokenAddress);
    this.softTokenContract = new ethers.Contract(
      softTokenAddress,
      ERC20.abi,
      provider.getSigner()
    );
    this.softTokenFaucet = new ethers.Contract(
      softTokenFaucetAddress,
      SoftTokenFaucet.abi,
      provider.getSigner()
    );
    console.log("CoreStakingManagerService contract instance:", this.contract);

    this.totalGovTokenBalance = this.totalGovTokenBalance.bind(this);
    this.allowance = this.allowance.bind(this);
    this.approve = this.approve.bind(this);
  }

  async totalGovTokenBalance() {
    const signerAddress = await this.provider.getSigner().getAddress();
    const balance = await this.softTokenContract.balanceOf(signerAddress);
    console.log("totalGovTokenBalance() SOFT balance:", balance);
    return ethers.utils.formatUnits(balance, 18);
  }

  async totalTokensStaked() {
    const total = await this.contract.totalTokensStaked();
    console.log("totalTokensStaked():", total);
    return ethers.utils.formatUnits(total, 18);
  }

  async userDeposit() {
    const signerAddress = await this.provider.getSigner().getAddress();
    const total = await this.contract.userDeposit(signerAddress);
    console.log("userDeposit():", total);
    return ethers.utils.formatUnits(total, 18);
  }

  async userEarnedRewards() {
    const signerAddress = await this.provider.getSigner().getAddress();
    const total = await this.contract.earned(signerAddress);
    console.log("userEarnedRewards():", total);
    return ethers.utils.formatUnits(total, 18);
  }

  async allowance() {
    const signerAddress = await this.provider.getSigner().getAddress();
    const allowance = await this.softTokenContract.allowance(
      coreStakingManagerAddress,
      signerAddress
    );
    return allowance;
  }

  async approve(amount) {
    const tx = await this.softTokenContract.approve(
      coreStakingManagerAddress,
      ethers.utils.parseUnits(amount, 18)
    );

    toastUtils.displayToastMessage(
      "info",
      "Processing transaction...wait until button says 'Stake'",
      {
        autoClose: 10000,
        position: toast.POSITION.BOTTOM_LEFT,
      }
    );

    await tx.wait();
  }

  async stake(amount) {
    console.log("stake() amount:", amount);

    amount = ethers.utils.parseUnits(`${amount}`, 18);

    const tx = await this.contract.stake(amount);

    toastUtils.displayToastMessage("info", "Processing stake transaction...", {
      autoClose: 10000,
      position: toast.POSITION.BOTTOM_LEFT,
    });

    await tx.wait();
  }

  async unstake(amount) {
    console.log("unstake() amount:", amount);

    amount = ethers.utils.parseUnits(`${amount}`, 18);

    const tx = await this.contract.unstake(amount);

    toastUtils.displayToastMessage(
      "info",
      "Processing unstake transaction...",
      {
        autoClose: 10000,
        position: toast.POSITION.BOTTOM_LEFT,
      }
    );

    await tx.wait();
  }

  async claimStakingRewards() {
    console.log("claimStakingRewards()");
    const tx = await this.contract.claimRewards();

    toastUtils.displayToastMessage("info", "Processing claim transaction...", {
      autoClose: 10000,
      position: toast.POSITION.BOTTOM_LEFT,
    });

    await tx.wait();
  }

  async mintSOFTForUser() {
    console.log("mintSOFTForUser()");

    const tx = await this.softTokenFaucet.requestSOFT();

    toastUtils.displayToastMessage("info", "Processing mint transaction...", {
      autoClose: 10000,
      position: toast.POSITION.BOTTOM_LEFT,
    });

    await tx.wait();
  }
}

export default CoreStakingManagerService;
