import React from "react";
import "./index.css";

export const PageNotFound = () => {
  return (
    <div className="pagenotfound-container">
      <p>404 Page Not Found</p>
      <a href="/">Return home</a>
    </div>
  );
};
