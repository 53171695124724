import React from "react";
import { connect } from "react-redux";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { clearWeb3, initWeb3 } from "../../redux/actions/web3";
import Reserves from "./Reserves";
import Staking from "./Staking";
import Lock from "./Lock";
import Rewards from "./Rewards";
import config from "../../config";
import Button from "../../components/Common/Button";
import { PleaseConnectWallet } from "../../components/PleaseConnectWallet";
import WaitlistV0Service from "../../services/WaitlistV0Service";
import toastUtils from "../../utils/toasts";
import {
  addUserProperties,
  identifyCurrentAddress,
} from "../../utils/analytics";
import { switchNetwork } from "../../utils/network";
import "./index.css";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      brandNameHeader: "SoftLink",
      contentView: "connectWallet",
    };

    this.getWeb3 = this.getWeb3.bind(this);
    this.renderAppContent = this.renderAppContent.bind(this);
    this.renderAppHeader = this.renderAppHeader.bind(this);
    this.setAppContentView = this.setAppContentView.bind(this);
    this.renderAccountInfo = this.renderAccountInfo.bind(this);
  }

  async componentDidMount() {
    await this.getWeb3();
  }

  async getWeb3Modal() {
    const network = process.env.CURRENT_ETH_NETWORK || "mainnet";
    const infuraId = process.env.NEXT_PUBLIC_INFURA_ID || "";
    return new Web3Modal({
      network,
      cacheProvider: false,
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: { infuraId },
        },
      },
    });
  }

  async getWeb3(event) {
    try {
      if (event) event.preventDefault();
      console.log("connecting to client wallet...");

      const web3Modal = await this.getWeb3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      const accounts = await provider.listAccounts();
      const { chainId, name } = await provider.getNetwork();

      identifyCurrentAddress(accounts[0], () => {
        addUserProperties({ chainId, address: accounts[0], chainName: name });
      });

      // NOTE: Goerli chain for testing...
      if (
        process.env.NODE_ENV === "production" &&
        !config.availableChains.includes(chainId)
      ) {
        await switchNetwork(`0x${config.availableChains[0]}`);
        return this.getWeb3(event);
      }

      // NOTE: Temp gate for waitlisted members only
      const waitlistV0Service = new WaitlistV0Service(provider);
      if (!waitlistV0Service.hasEnteredWaitlist(accounts[0])) {
        return toastUtils.displayToastMessage(
          "error",
          "Only waitlisted members have early access: https://softlink.finance"
        );
      }

      this.props.dispatchInitWeb3({
        provider,
        chainId,
        chainName: name,
        account: accounts[0],
      });

      // TODO: When testing...uncomment and remove line below comment...
      this.setState({ contentView: "reserves" });
      // this.setState({ contentView: "lock" });
    } catch (error) {
      console.log("getWeb3() error:", error);
      toastUtils.displayToastMessage(
        "error",
        "Connection failure: Please try again"
      );
    }
  }

  setAppContentView(event, contentView) {
    event.preventDefault();
    if (this.props.web3.provider == null) return;
    this.setState({ contentView });
  }

  renderAccountInfo() {
    let action = this.getWeb3;
    let label = "Connect Wallet";
    if (this.props.web3.provider !== null && this.props.web3.account !== null) {
      action = this.props.dispatchClearWeb3;
      label = "Disconnect";
    }
    return (
      <Button className="account-connect-button" onClick={action}>
        {label}
      </Button>
    );
  }

  isActiveNativeLink(isActive) {
    return isActive ? "active-link" : "";
  }

  renderAppHeader() {
    return (
      <header className="row">
        <div className="brand-name-container col-3">
          <a href="/">{this.state.brandNameHeader}</a>
        </div>
        <div className="header-nav-container col-6">
          <div className="nav-option">
            <a
              className={this.isActiveNativeLink(
                this.state.contentView === "reserves"
              )}
              href="#reserves"
              onClick={(e) => this.setAppContentView(e, "reserves")}
            >
              Reserves
            </a>
          </div>
          <div className="nav-option">
            <a
              className={`nav-padded-link ${this.isActiveNativeLink(
                this.state.contentView === "staking"
              )}`}
              href="#staking"
              onClick={(e) => this.setAppContentView(e, "staking")}
            >
              Stake
            </a>
          </div>
          <div className="nav-option">
            <a
              className={`nav-padded-link ${this.isActiveNativeLink(
                this.state.contentView === "lock"
              )}`}
              href="#lock"
              onClick={(e) => this.setAppContentView(e, "lock")}
            >
              Lock
            </a>
          </div>
          <div className="nav-option">
            <a
              className={`nav-padded-link ${this.isActiveNativeLink(
                this.state.contentView === "rewards"
              )}`}
              href="#rewards"
              onClick={(e) => this.setAppContentView(e, "rewards")}
            >
              Rewards
            </a>
          </div>

          <div className="nav-option">
            <a
              className="nav-padded-link"
              href="https://docs.softlink.finance"
              target="_blank"
              rel="noreferrer"
            >
              Docs
            </a>
          </div>
        </div>
        <div className="account-info-container col-3">
          {this.renderAccountInfo()}
        </div>
      </header>
    );
  }

  renderAppContent() {
    switch (this.state.contentView) {
      case "reserves":
        return <Reserves />;
      case "staking":
        return <Staking />;
      case "lock":
        return <Lock />;
      case "rewards":
        return <Rewards />;
      default:
        return <PleaseConnectWallet />;
    }
  }

  render() {
    return (
      <div className="main-container">
        <div className="app-desktop-container">
          {this.renderAppHeader()}
          {this.renderAppContent()}
        </div>
        <div className="app-mobile">
          SoftLink Beta (Goerli) is not available for mobile devices.
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  web3: state.web3,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchInitWeb3: (initPayload) => dispatch(initWeb3(initPayload)),
  dispatchClearWeb3: () => dispatch(clearWeb3()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
