// Types: (https://github.com/fkhadra/react-toastify#toast)
import { toast } from 'react-toastify';

const toastUtils = {
  displayToastMessage(
    type,
    message,
    options = { autoClose: 4000, position: toast.POSITION.BOTTOM_LEFT },
  ) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('toastUtils.displayToastMessage()', type, message);
    }
    toast[type](message, options);
  },
};

export default toastUtils;
