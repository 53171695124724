const logEventData = (label, data) => {
  if (process.env.NODE_ENV !== "production") {
    console.log(label, data);
  }
};

export const identifyCurrentAddress = (address, cb) => {
  if (window.heap) {
    logEventData("identifyCurrentAddress:", address);
    window.heap.identify(address);
    if (cb) cb();
  }
};

export const addUserProperties = (propData) => {
  if (window.heap) {
    logEventData("addUserProperties:", propData);
    window.heap.addUserProperties(propData);
  }
};

export const trackEvent = (eventName, eventData) => {
  if (window.heap) {
    logEventData("addUserProperties:", eventName, eventData);
    window.heap.track(eventName, eventData);
  }
};
