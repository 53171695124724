import {
  INIT_WEB3,
  CLEAR_WEB3,
  CLEAR_PROVIDER,
  CLEAR_ACCOUNT,
  SET_PROVIDER,
  SET_ACCOUNT,
} from '../constants';

export const initWeb3 = (initPayload) => {
  return {
    type: INIT_WEB3,
    ...initPayload,
  };
};

export const clearWeb3 = () => {
  return { type: CLEAR_WEB3 };
};

export const clearProvider = () => {
  return { type: CLEAR_PROVIDER };
};

export const setProvider = (provider) => {
  return { type: SET_PROVIDER, provider };
};

export const clearAccount = () => {
  return { type: CLEAR_ACCOUNT };
};

export const setAccount = (account) => {
  return { type: SET_ACCOUNT, account };
};
