import React from "react";
import "./index.css";

export const PleaseConnectWallet = () => {
  return (
    <div className="please-connect-container">
      <p>Please connect your wallet.</p>
    </div>
  );
};
