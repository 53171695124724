import { ethers } from "ethers";
import { toast } from "react-toastify";
import {
  coreRewardsDistributorAddress,
  ownedNativeSnipingReserveAddress,
} from "../../config/contracts";
import ERC20 from "../../contracts/dependencies/openzeppelin/ERC20.sol/ERC20.json";
import CoreReserve from "../../contracts/CoreReserve.sol/CoreReserve.json";
import CoreRewardsDistributor from "../../contracts/CoreRewardsDistributor.sol/CoreRewardsDistributor.json";
import toastUtils from "../../utils/toasts";

class CoreRewardsDistributorService {
  constructor(provider) {
    this.provider = provider;
    this.signer = provider.getSigner();
    this.contract = new ethers.Contract(
      coreRewardsDistributorAddress,
      CoreRewardsDistributor.abi,
      this.signer
    );
    console.log("CoreRewardsDistributor contract instance:", this.contract);

    this.fetchTotalNativeEarned = this.fetchTotalNativeEarned.bind(this);
    this.fetchTotalERC20Earned = this.fetchTotalERC20Earned.bind(this);
  }

  fetchDistributionWaitingPeriod() {
    return this.contract.distributionWaitingPeriod();
  }

  fetchLastDistribution() {
    return this.contract.currentDistributionStartTime();
  }

  async fetchTotalNativeEarned() {
    let earned = await this.contract.totalNativeEarned(
      await this.signer.getAddress()
    );

    earned = ethers.utils.formatEther(earned.toString());

    return earned.substring(0, 8);
  }

  async fetchTotalERC20Earned(erc20ReserveAddressToFetch) {
    try {
      erc20ReserveAddressToFetch = ethers.utils.getAddress(
        erc20ReserveAddressToFetch.trim()
      );

      if (erc20ReserveAddressToFetch === ethers.constants.AddressZero) {
        throw new Error("Please provide a correct address");
      }
      let earned = await this.contract.totalERC20Earned(
        await this.signer.getAddress(),
        erc20ReserveAddressToFetch
      );

      const coreReserveContract = new ethers.Contract(
        erc20ReserveAddressToFetch,
        CoreReserve.abi,
        this.signer
      );
      const { reserveToken } = await coreReserveContract.reserveData();

      console.log("reserveToken:", reserveToken);

      const erc20Contract = new ethers.Contract(
        reserveToken,
        ERC20.abi,
        this.signer
      );
      const decimals = await erc20Contract.decimals();

      earned = ethers.utils.formatUnits(earned, decimals);

      return earned.substring(0, 8);
    } catch (error) {
      console.log("fetchTotalERC20Earned error:", error);
      return 0;
    }
  }

  async claimRewards(erc20ReserveAddressToFetch) {
    if (erc20ReserveAddressToFetch === ethers.constants.AddressZero) {
      throw new Error("Please provide a correct address");
    }
    if (erc20ReserveAddressToFetch.trim() === "") {
      erc20ReserveAddressToFetch = ownedNativeSnipingReserveAddress;
    }

    const tx = await this.contract.claimRewards(erc20ReserveAddressToFetch);

    toastUtils.displayToastMessage("info", "Processing transaction...", {
      autoClose: 10000,
      position: toast.POSITION.BOTTOM_LEFT,
    });

    await tx.wait();
  }
}

export default CoreRewardsDistributorService;
