import React from "react";
import { connect } from "react-redux";
import Button from "../../../components/Common/Button";
import Input from "../../../components/Common/Input";
import { PleaseConnectWallet } from "../../../components/PleaseConnectWallet";
import { ownedNativeSnipingReserveAddress } from "../../../config/contracts";
import CoreRewardsDistributorService from "../../../services/CoreRewardsDistributorService";
import conversionUtils from "../../../utils/conversion";
import toastUtils from "../../../utils/toasts";
import "./index.css";

class Rewards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchingRewardsData: true,
      currentDistributionStartTime: null,
      distributionWaitingPeriod: null,
      earningsData: null,
      erc20ReserveAddressToFetch: "",
      earlyClaimError:
        "execution reverted: block.timestamp must be < currentDistributionStartTime + distributionWaitingPeriod",
      correctEarlyClaimError:
        "execution reverted: block.timestamp must be > currentDistributionStartTime + distributionWaitingPeriod",
    };

    this.coreRewardsDistributorService = new CoreRewardsDistributorService(
      props.web3.provider
    );

    this.fetchInitialRewardsData = this.fetchInitialRewardsData.bind(this);
    this.renderReserveRewardsInfo = this.renderReserveRewardsInfo.bind(this);
    this.fetchRewardsForReserve = this.fetchRewardsForReserve.bind(this);
    this.claimRewardsForReserve = this.claimRewardsForReserve.bind(this);
  }

  async componentDidMount() {
    await this.fetchInitialRewardsData();
  }

  async fetchInitialRewardsData() {
    try {
      const signerAddress = await this.props.web3.provider
        .getSigner()
        .getAddress();
      const totalNativeEarned =
        await this.coreRewardsDistributorService.fetchTotalNativeEarned();
      const totalERC20Earned =
        await this.coreRewardsDistributorService.fetchTotalERC20Earned(
          signerAddress
        );
      let currentDistributionStartTime =
        await this.coreRewardsDistributorService.fetchLastDistribution();
      let distributionWaitingPeriod =
        await this.coreRewardsDistributorService.fetchDistributionWaitingPeriod();

      const options = {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
      };

      distributionWaitingPeriod = new Date(
        currentDistributionStartTime.toNumber() * 1000 +
          distributionWaitingPeriod.toNumber() * 1000
      ).toLocaleDateString("en-us", options);
      currentDistributionStartTime = new Date(
        currentDistributionStartTime.toNumber() * 1000
      ).toLocaleDateString("en-us", options);

      this.setState({
        currentDistributionStartTime,
        distributionWaitingPeriod,
        fetchingRewardsData: false,
        earningsData: {
          totalNativeEarned,
          totalERC20Earned,
          reserveAddress: ownedNativeSnipingReserveAddress,
        },
      });
    } catch (error) {
      console.log("fetchInitialRewardsData error:", error);

      let message =
        "Failed to fetch initial rewards data. Please review your values and/or the console for errors";
      if (error.message && typeof error.message === "string") {
        message = error.message;
      }

      return toastUtils.displayToastMessage("error", message);
    }
  }

  renderReserveRewardsInfo() {
    if (this.state.fetchingRewardsData) {
      return (
        <div className="rewards-info-container">
          <p>Fetching rewards data...</p>
        </div>
      );
    }

    const time =
      this.state.currentDistributionStartTime === null
        ? "..."
        : this.state.currentDistributionStartTime;
    const period =
      this.state.distributionWaitingPeriod === null
        ? "..."
        : this.state.distributionWaitingPeriod;
    const { totalNativeEarned, totalERC20Earned, reserveAddress } =
      this.getReserveData();
    const chainId = this.props.web3.chainId;

    return (
      <div className="rewards-info-container">
        <div className="rewards-info-label-container">
          <p>
            <strong>Reserve Address:</strong> {reserveAddress}
          </p>
        </div>
        <div className="rewards-info-label-container">
          <p>
            <strong>
              Total Claimed Rewards [Native] (
              {conversionUtils.chainIDToTokenSymbol(chainId)}):
            </strong>{" "}
            {totalNativeEarned}
          </p>
        </div>
        <div className="rewards-info-label-container">
          <p>
            <strong>Total Claimed Rewards [ERC20]:</strong> {totalERC20Earned}
          </p>
        </div>
        <div className="rewards-info-label-container">
          <p>
            <strong>Distribution State Date:</strong> {time.toString()}
          </p>
        </div>
        <div className="rewards-info-label-container">
          <p>
            <strong>Distribution End Date:</strong> {period.toString()}
          </p>
        </div>
      </div>
    );
  }

  async fetchRewardsForReserve(evt) {
    try {
      if (evt) evt.preventDefault();
      const totalNativeEarned =
        this.state.erc20ReserveAddressToFetch ===
        ownedNativeSnipingReserveAddress
          ? await this.coreRewardsDistributorService.fetchTotalNativeEarned()
          : 0;
      const totalERC20Earned =
        await this.coreRewardsDistributorService.fetchTotalERC20Earned(
          this.state.erc20ReserveAddressToFetch
        );
      this.setState({
        fetchingRewardsData: false,
        earningsData: {
          totalNativeEarned: totalNativeEarned.toString(),
          totalERC20Earned: totalERC20Earned.toString(),
          reserveAddress: this.state.erc20ReserveAddressToFetch,
        },
      });
    } catch (error) {
      console.log("fetchRewardsForReserve error:", error);

      let message =
        "Failed to fetch rewards for the given address. Please review your values and/or the console for errors";
      if (error.message && typeof error.message === "string") {
        message = error.message;
      }

      return toastUtils.displayToastMessage("error", message);
    }
  }

  getReserveData() {
    if (this.state.earningsData !== null) {
      return this.state.earningsData;
    }
    return {
      totalNativeEarned: "...",
      totalERC20Earned: "...",
      reserveAddress: "...",
    };
  }

  async claimRewardsForReserve(evt) {
    try {
      if (evt) evt.preventDefault();
      await this.coreRewardsDistributorService.claimRewards(
        this.state.erc20ReserveAddressToFetch
      );
    } catch (error) {
      // TODO: Handle error handling across the application better...
      console.log("claimRewardsForReserve() error:", error);
      if (error.error && error.error.message) {
        // TODO: Temporary...update before mainnet
        if (error.error.message === this.state.earlyClaimError) {
          error.error.message = this.state.correctEarlyClaimError;
        }
        toastUtils.displayToastMessage("error", error.error.message);
      }
    }
  }

  render() {
    if (this.props.web3.provider === null || this.props.web3.account === null) {
      return <PleaseConnectWallet />;
    }
    return (
      <div className="main-content-container">
        <div className="rewards-header-container row">
          <div className="rewards-title-container col-2">
            <h1>Rewards</h1>
          </div>
          <div className="rewards-search-input-container col-8">
            <Input
              type="text"
              placeholder="Enter an address: 0x..."
              value={this.state.erc20ReserveAddressToFetch}
              onChange={(e) =>
                this.setState({ erc20ReserveAddressToFetch: e.target.value })
              }
            ></Input>
            <Button
              className="rewards-search-button"
              onClick={this.fetchRewardsForReserve}
            >
              Search
            </Button>
          </div>
          <div className="rewards-claim-container col-2">
            <Button
              className="rewards-claim-button"
              onClick={this.claimRewardsForReserve}
            >
              Claim
            </Button>
          </div>
        </div>
        <div className="rewards-content-container row">
          {this.renderReserveRewardsInfo()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  web3: state.web3,
});

export default connect(mapStateToProps, null)(Rewards);
