import { ethers } from "ethers";
import { waitlistV0Address } from "../../config/contracts";
import WaitlistV0 from "../../contracts/WaitlistV0.sol/WaitlistV0.json";

class WaitlistV0Service {
  constructor(provider) {
    this.provider = provider;
    this.contract = new ethers.Contract(
      waitlistV0Address,
      WaitlistV0.abi,
      provider.getSigner()
    );
    console.log("WaitlistV0Service contract instance:", this.contract);
  }

  async hasEnteredWaitlist() {
    return await this.contract.hasEntered(
      await this.provider.getSigner().getAddress()
    );
  }
}

export default WaitlistV0Service;
