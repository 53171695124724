import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import { Cookies } from "react-cookie-consent";
import { ToastContainer } from "react-toastify";
import { history } from "../../redux/store";
import { PageNotFound } from "../PageNotFound";
import App from "../../containers/App";
import {
  CookiesConsentBanner,
  removeAnalyticCookies,
} from "../../components/CookiesConsentBanner";
import "react-toastify/dist/ReactToastify.css";

const handleRouteMiddleware = (Component, options) => {
  const consentCookie = Cookies.get("consent-cookie");
  if (consentCookie && consentCookie === "false") {
    removeAnalyticCookies();
  }
  return <Component {...options.history} />;
};

const Router = (props) => {
  return (
    <ConnectedRouter history={history}>
      <>
        <Switch>
          <Route
            exact
            path="/"
            component={(history) => handleRouteMiddleware(App, { history })}
          ></Route>
          <Route component={PageNotFound}></Route>
        </Switch>
        <CookiesConsentBanner />
        <ToastContainer />
      </>
    </ConnectedRouter>
  );
};

export default Router;
