import { ethers } from "ethers";
import { toast } from "react-toastify";
import { coreReserveFactoryAddress } from "../../config/contracts.js";
import CoreReserveFactory from "../../contracts/CoreReserveFactory.sol/CoreReserveFactory.json";
import ERC20 from "../../contracts/dependencies/openzeppelin/ERC20.sol/ERC20.json";
import toastUtils from "../../utils/toasts/index.js";

class CoreReserveFactoryService {
  constructor(provider) {
    this.provider = provider;
    this.contract = new ethers.Contract(
      coreReserveFactoryAddress,
      CoreReserveFactory.abi,
      provider.getSigner()
    );
    console.log("CoreReserveFactory contract instance:", this.contract);

    this.checkAllowanceFor = this.checkAllowanceFor.bind(this);
    this.approveTransferFor = this.approveTransferFor.bind(this);
    this.fetchReserve = this.fetchReserve.bind(this);
    this.createReserve = this.createReserve.bind(this);
  }

  nextReserveIndex() {
    return this.contract.nextReserveIndex();
  }

  getReserveAddressForReserveToken(reserveToken) {
    return this.contract.reserveForReserveToken(reserveToken);
  }

  fetchReserve(reserveIndex) {
    return this.contract.reserveForIndex(reserveIndex);
  }

  async checkAllowanceFor(reserveToken) {
    const erc20Contract = new ethers.Contract(
      reserveToken,
      ERC20.abi,
      this.provider.getSigner()
    );
    const signerAddress = await this.provider.getSigner().getAddress();
    return erc20Contract.allowance(coreReserveFactoryAddress, signerAddress);
  }

  async approveTransferFor(reserveToken, amount) {
    const erc20Contract = new ethers.Contract(
      reserveToken,
      ERC20.abi,
      this.provider.getSigner()
    );
    const decimals = await erc20Contract.decimals();

    amount = ethers.utils.parseUnits(`${amount}`, decimals);

    const signerAddress = await this.provider.getSigner().getAddress();
    const allowance = await erc20Contract.allowance(
      coreReserveFactoryAddress,
      signerAddress
    );
    console.log("allowance:", allowance);
    if (allowance.gte(amount)) {
      return;
    }

    const tx = await erc20Contract.approve(coreReserveFactoryAddress, amount);

    toastUtils.displayToastMessage(
      "info",
      "Processing transaction...wait until button says 'Create'",
      {
        autoClose: 10000,
        position: toast.POSITION.BOTTOM_LEFT,
      }
    );

    await tx.wait();
  }

  async createReserve({
    reserveType,
    isNativeTokenReserve,
    reserveToken,
    reserveTokenInitialAmount,
  }) {
    let options = {};

    if (isNativeTokenReserve) {
      // ETH (wei 10**18)
      options.value = ethers.utils.parseEther(`${reserveTokenInitialAmount}`);
    } else {
      const erc20Contract = new ethers.Contract(
        reserveToken,
        ERC20.abi,
        this.provider.getSigner()
      );
      const decimals = await erc20Contract.decimals();
      reserveTokenInitialAmount = ethers.utils.parseUnits(
        `${reserveTokenInitialAmount}`,
        decimals
      );
    }

    const txData = {
      reserveType,
      isNativeTokenReserve,
      reserveToken,
      reserveTokenInitialAmount,
    };
    console.log("txData:", txData);

    console.log("options:", options);

    return this.contract.createReserve(txData, options);
  }
}

export default CoreReserveFactoryService;
