import { ethers } from "ethers";
import { toast } from "react-toastify";
import {
  coreLockManagerAddress,
  softTokenAddress,
} from "../../config/contracts";
import CoreLockManager from "../../contracts/CoreLockManager.sol/CoreLockManager.json";
import ERC20 from "../../contracts/dependencies/openzeppelin/ERC20.sol/ERC20.json";
import toastUtils from "../../utils/toasts";

class CoreLockManagerService {
  constructor(provider) {
    this.provider = provider;
    this.contract = new ethers.Contract(
      coreLockManagerAddress,
      CoreLockManager.abi,
      provider.getSigner()
    );
    this.softTokenContract = new ethers.Contract(
      softTokenAddress,
      ERC20.abi,
      provider.getSigner()
    );
    console.log("CoreLockManagerService contract instance:", this.contract);
  }

  async totalGovTokenBalance() {
    const signerAddress = await this.provider.getSigner().getAddress();
    const balance = await this.softTokenContract.balanceOf(signerAddress);
    console.log("totalGovTokenBalance() SOFT balance:", balance);
    return ethers.utils.formatUnits(balance, 18);
  }

  async totalTokensLocked() {
    const total = await this.softTokenContract.balanceOf(
      coreLockManagerAddress
    );
    console.log("totalTokensLocked():", total);
    return ethers.utils.formatUnits(total, 18);
  }

  async fetchLockedDeposit() {
    const signerAddress = await this.provider.getSigner().getAddress();
    const lockedDeposit = await this.contract.userLockedDeposit(signerAddress);
    console.log("lockedDeposit:", lockedDeposit);
    let endTimestamp = "No locked tokens";
    if (lockedDeposit.endTimestamp.toNumber() > 0) {
      endTimestamp = new Date(
        lockedDeposit.endTimestamp.toNumber() * 1000
      ).toLocaleDateString("en-us", {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    }
    return {
      endTimestamp,
      balance: ethers.utils.formatUnits(lockedDeposit.balance, 18),
    };
  }

  async allowance() {
    const signerAddress = await this.provider.getSigner().getAddress();
    const allowance = await this.softTokenContract.allowance(
      coreLockManagerAddress,
      signerAddress
    );
    return allowance;
  }

  async approve(amount) {
    const tx = await this.softTokenContract.approve(
      coreLockManagerAddress,
      ethers.utils.parseUnits(amount, 18)
    );

    toastUtils.displayToastMessage(
      "info",
      "Processing transaction...wait until button says 'Lock' or 'Update'",
      {
        autoClose: 10000,
        position: toast.POSITION.BOTTOM_LEFT,
      }
    );

    await tx.wait();
  }

  async createLock(amount, weeks) {
    console.log("createLock() amount, weeks:", amount, weeks);

    amount = ethers.utils.parseUnits(`${amount}`, 18);

    const tx = await this.contract.createLock(softTokenAddress, amount, weeks);

    toastUtils.displayToastMessage("info", "Processing lock transaction...", {
      autoClose: 10000,
      position: toast.POSITION.BOTTOM_LEFT,
    });

    await tx.wait();
  }

  async increaseLockBalance(amount) {
    console.log("increaseLockBalance() amount:", amount);

    amount = ethers.utils.parseUnits(`${amount}`, 18);

    const tx = await this.contract.increaseLockBalance(
      softTokenAddress,
      amount
    );

    toastUtils.displayToastMessage(
      "info",
      "Processing lock balance transaction...",
      {
        autoClose: 10000,
        position: toast.POSITION.BOTTOM_LEFT,
      }
    );

    await tx.wait();
  }

  async increaseLockTime(weeks) {
    console.log("increaseLockTime() weeks:", weeks);

    const tx = await this.contract.increaseLockTime(softTokenAddress, weeks);

    toastUtils.displayToastMessage(
      "info",
      "Processing increase lock time transaction...",
      {
        autoClose: 10000,
        position: toast.POSITION.BOTTOM_LEFT,
      }
    );

    await tx.wait();
  }

  async increaseLockBalanceAndTime(amount, weeks) {
    console.log("increaseLockBalanceAndTime() amount, weeks:", amount, weeks);

    amount = ethers.utils.parseUnits(`${amount}`, 18);

    const tx = await this.contract.increaseLockBalanceAndTime(
      softTokenAddress,
      amount,
      weeks
    );

    toastUtils.displayToastMessage(
      "info",
      "Processing increase lock balance + time transaction...",
      {
        autoClose: 10000,
        position: toast.POSITION.BOTTOM_LEFT,
      }
    );

    await tx.wait();
  }

  async claimLockedDeposit() {
    console.log("claimLockedDeposit()");

    const tx = await this.contract.claimLockedDeposit(softTokenAddress);

    toastUtils.displayToastMessage("info", "Processing claim transaction...", {
      autoClose: 10000,
      position: toast.POSITION.BOTTOM_LEFT,
    });

    await tx.wait();
  }
}

export default CoreLockManagerService;
