import { ethers } from "ethers";
import React from "react";
import { connect } from "react-redux";
import Reserve from "./Reserve";
import Button from "../../../components/Common/Button";
import Input from "../../../components/Common/Input";
import { ReserveSearchForm } from "../../../components/ReserveSearchForm";
import { PleaseConnectWallet } from "../../../components/PleaseConnectWallet";
import { ownedNativeSnipingReserveAddress } from "../../../config/contracts";
import CoreReserveFactoryService from "../../../services/CoreReserveFactoryService";
import CoreReserveFactoryGraphQLService from "../../../services/CoreReserveFactoryService/graphql";
import CoreReserveService from "../../../services/CoreReserveService";
import toastUtils from "../../../utils/toasts";
import conversionUtils from "../../../utils/conversion";
import "./index.css";

class Reserves extends React.Component {
  constructor(props) {
    super(props);

    this.initialState = {
      contentView: "reservesList",
      limit: 4,
      offset: 0,
      loadingReserves: true,
      reserves: [],
      displayShowMoreButton: true,
      previousScrollY: "0",
      selectedReserve: null,
      // selectedReserve: {
      //   reserveTVL: "3.001",
      //   tokenSymbol: "ETH",
      //   depositAmount: "3000000000000000000",
      //   address: "0x89Ea9d47Ea4c29ACCc19723F4B27971908f788A8",
      //   creator: "0x0e2aF21ac288bAD7d63E14dB529a452F90a397fc",
      //   reserveType: 0,
      //   isNativeTokenReserve: true,
      //   reserveToken: "0x0000000000000000000000000000000000000000",
      // },
      createNewReserveFormButtonLabel: "Approve",
      createReserveForm: {
        reserveType: 1,
        isNativeTokenReserve: false,
        reserveToken: "",
        reserveTokenInitialAmount: 0,
      },
    };

    this.state = this.initialState;

    this.coreReserveFactoryService = new CoreReserveFactoryService(
      this.props.web3.provider
    );
    this.coreReserveFactoryGraphQLService =
      new CoreReserveFactoryGraphQLService();

    this.initReservesList = this.initReservesList.bind(this);
    this.fetchReserves = this.fetchReserves.bind(this);
    this.handleReserveSearchFormUpdateList =
      this.handleReserveSearchFormUpdateList.bind(this);
    this.renderReservesList = this.renderReservesList.bind(this);
    this.handleReserveSelection = this.handleReserveSelection.bind(this);
    this.updateContentView = this.updateContentView.bind(this);
    this.renderCreateReserveForm = this.renderCreateReserveForm.bind(this);
    this.updateCreateReserveForm = this.updateCreateReserveForm.bind(this);
    this.createNewReserve = this.createNewReserve.bind(this);
  }

  componentDidMount() {
    this.initReservesList();
  }

  async initReservesList() {
    try {
      const nextReserveIndex =
        await this.coreReserveFactoryService.nextReserveIndex();
      console.log("nextReserveIndex:", nextReserveIndex);

      // Offset + limit in DESC order
      this.setState(
        {
          offset: nextReserveIndex - 1,
          displayShowMoreButton: true,
          reserves: [],
        },
        async () => {
          await this.fetchReserves();
        }
      );
    } catch (error) {
      console.log("initReservesList() error:", error);
      toastUtils.displayToastMessage(
        "error",
        "Failed to load page. Please try again."
      );
    }
  }

  fetchReserves(evt) {
    this.setState({ loadingReserves: true }, async () => {
      try {
        if (evt) evt.preventDefault();

        const { offset, limit, displayShowMoreButton } = this.state;
        if (offset < 0) {
          if (!displayShowMoreButton) return;
          return this.setState({
            loadingReserves: false,
            displayShowMoreButton: false,
          });
        }

        let promises = [];
        for (let i = offset; i > offset - limit; i--) {
          if (i < 0) break;
          promises.push(this.coreReserveFactoryService.fetchReserve(i));
        }

        const reserveAddresses = await Promise.all(promises);
        console.log("reserveAddresses:", reserveAddresses);

        promises = [];

        reserveAddresses.forEach((reserveAddress) => {
          if (reserveAddress !== ethers.constants.AddressZero) {
            const coreReserveService = new CoreReserveService({
              reserveAddress,
              provider: this.props.web3.provider,
              chainCurrencySymbol: conversionUtils.chainIDToTokenSymbol(
                this.props.web3.chainId
              ),
            });
            promises.push(coreReserveService.fetchReserveData());
          }
        });

        const reserves = await Promise.all(promises);
        console.log("reserves", reserves);

        this.setState(
          {
            reserves: [...this.state.reserves, ...reserves],
            loadingReserves: false,
            offset: offset - limit,
          },
          () => {
            if (this.state.offset < 0) {
              return this.setState({ displayShowMoreButton: false });
            }
          }
        );
      } catch (error) {
        this.setState({ loadingReserves: false }, () => {
          console.log("fetchReserves() error:", error);
          toastUtils.displayToastMessage(
            "error",
            "Failed to fetch reserves: Please try again"
          );
        });
      }
    });
  }

  handleReserveSelection(selectedReserve) {
    this.setState({ selectedReserve, previousScrollY: window.scrollY }, () =>
      this.updateContentView(undefined, "selectedReserveView")
    );
  }

  renderReservesList() {
    if (this.props.web3.provider === null || this.props.web3.account === null) {
      return <PleaseConnectWallet />;
    }
    return (
      <div className="reserves-list-container row">
        <div className="reserves-list row">
          {this.state.reserves.map((reserve, i) => {
            return (
              <div key={i} className="reserves-item-container col-md-6 col-12">
                <div
                  className="reserve-item-info"
                  onClick={() => this.handleReserveSelection(reserve)}
                >
                  <p>Token: {reserve.tokenSymbol}</p>
                  <p className="reserve-item-info-label type">
                    Type:{" "}
                    {conversionUtils.reserveTypeValueToLabel(
                      reserve.reserveType
                    )}
                  </p>
                  <p className="reserve-item-info-label tvl">
                    TVL:{" "}
                    {`${conversionUtils.trimString(reserve.reserveTVL, 10)} ${
                      reserve.tokenSymbol
                    }`}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        {this.renderShowMoreReservesButton(this.state)}
      </div>
    );
  }

  renderShowMoreReservesButton({ displayShowMoreButton, loadingReserves }) {
    if (displayShowMoreButton) {
      let action = this.fetchReserves;
      let label = "Show More";
      if (loadingReserves) {
        action = (e) => e.preventDefault();
        label = "Loading...";
      }
      return (
        <div className="show-more-reserves-container row">
          <Button onClick={action}>{label}</Button>
        </div>
      );
    }
  }

  updateCreateReserveForm(key, value) {
    switch (key) {
      case "reserveType":
        value = Number(value);
        break;
      case "isNativeTokenReserve":
        value = Boolean(Number(value));
        break;
      default:
        break;
    }

    this.setState(
      {
        createReserveForm: {
          ...this.state.createReserveForm,
          [key]: value,
        },
      },
      () => console.log(this.state.createReserveForm)
    );
  }

  async createNewReserve(evt) {
    try {
      if (evt) evt.preventDefault();

      let {
        reserveType,
        isNativeTokenReserve,
        reserveToken,
        reserveTokenInitialAmount,
      } = this.state.createReserveForm;

      if (this.state.createNewReserveFormButtonLabel === "Approve") {
        if (!isNativeTokenReserve) {
          await this.coreReserveFactoryService.approveTransferFor(
            reserveToken,
            reserveTokenInitialAmount
          );
        }
        return this.setState({ createNewReserveFormButtonLabel: "Create" });
      }
      if (isNaN(reserveTokenInitialAmount) || reserveTokenInitialAmount <= 0) {
        return toastUtils.displayToastMessage(
          "error",
          "Initial Deposit Amount must be a number greater than 0"
        );
      }

      reserveTokenInitialAmount = Number(reserveTokenInitialAmount);

      await this.coreReserveFactoryService.createReserve({
        reserveType,
        isNativeTokenReserve,
        reserveToken,
        reserveTokenInitialAmount,
      });

      toastUtils.displayToastMessage(
        "success",
        "Your transaction has been submitted."
      );
      toastUtils.displayToastMessage(
        "info",
        "Refresh once the transaction completes successfully to see it in the list."
      );

      this.setState(this.initialState, async () => {
        await this.initReservesList();
      });
    } catch (error) {
      console.log("Error creating reserve:", error);

      let message =
        "Failed to create a new reserve. Please review your values and/or the console for errors";
      if (error.message && typeof error.message === "string") {
        message = error.message;
      }
      if (error.reason && typeof error.reason === "string") {
        message = error.reason;
      }

      return toastUtils.displayToastMessage("error", message);
    }
  }

  renderCreateReserveForm() {
    return (
      <div className="create-reserve-container">
        <div className="create-reserve-header">
          <p>Create a new reserve below:</p>
        </div>
        <form className="create-reserve-form">
          <div className="create-reserve-form-input-container">
            <label>Reserve Token Address (Only for Non-Native reserves):</label>
            <Input
              type="text"
              placeholder="0xEeee...."
              value={this.state.createReserveForm.reserveToken}
              onChange={(e) =>
                this.updateCreateReserveForm("reserveToken", e.target.value)
              }
            ></Input>
          </div>
          <div className="create-reserve-form-input-container">
            <label>Initial Deposit Amount (Must be over 0):</label>
            <Input
              type="text"
              placeholder="1234"
              value={this.state.createReserveForm.reserveTokenInitialAmount}
              onChange={(e) =>
                this.updateCreateReserveForm(
                  "reserveTokenInitialAmount",
                  e.target.value
                )
              }
            ></Input>
            <span className="form-token-symbol">
              {" "}
              {this.state.createReserveForm.isNativeTokenReserve &&
                conversionUtils.chainIDToTokenSymbol(this.props.web3.chainId)}
            </span>
          </div>
          <div className="create-reserve-form-input-container">
            <Button onClick={this.createNewReserve}>
              {this.state.createNewReserveFormButtonLabel}
            </Button>
          </div>
        </form>
      </div>
    );
  }

  renderSelectedDataView() {
    return <Reserve selectedReserve={this.state.selectedReserve} />;
  }

  updateContentView(evt, contentView) {
    if (evt) evt.preventDefault();
    this.setState({ contentView }, () => {
      if (contentView === "reservesList") {
        window.scrollTo(0, parseInt(this.state.previousScrollY));
        this.setState({ previousScrollY: "0" });
      }
    });
  }

  displayRefreshReservesButton() {
    if (this.state.contentView === "reservesList") {
      return (
        <Button
          className="reserves-action-button"
          onClick={(evt) => window.location.reload()}
        >
          Refresh
        </Button>
      );
    }
  }

  displayBackToReservesListButton() {
    if (this.state.contentView !== "reservesList") {
      return (
        <Button
          className="reserves-action-button"
          onClick={(e) => this.updateContentView(e, "reservesList")}
        >
          Back
        </Button>
      );
    }
  }

  displayCreateReserveButton() {
    if (this.state.contentView !== "createReserveForm") {
      return (
        <Button
          className="reserves-action-button"
          onClick={(e) => this.updateContentView(e, "createReserveForm")}
        >
          Create Reserve
        </Button>
      );
    }
  }

  async handleReserveSearchFormUpdateList(reserveData) {
    if (reserveData === null) {
      return await this.initReservesList();
    }

    const reserveAddress =
      reserveData.reserveToken === ethers.constants.AddressZero
        ? ownedNativeSnipingReserveAddress
        : this.coreReserveFactoryService.getReserveAddressForReserveToken(
            reserveData.reserveToken
          );
    const coreReserveService = new CoreReserveService({
      reserveAddress,
      provider: this.props.web3.provider,
      chainCurrencySymbol: conversionUtils.chainIDToTokenSymbol(
        this.props.web3.chainId
      ),
    });

    reserveData = await coreReserveService.fetchReserveData();

    this.setState({
      reserves: [reserveData],
      limit: 4,
      offset: 0,
      displayShowMoreButton: false,
    });
  }

  displaySearchBar() {
    if (this.state.contentView === "reservesList") {
      return (
        <ReserveSearchForm
          offset={this.state.offset}
          limit={this.state.limit}
          coreReserveFactoryGraphQLService={
            this.coreReserveFactoryGraphQLService
          }
          onUpdateReservesList={this.handleReserveSearchFormUpdateList}
        />
      );
    }
  }

  displayActionsRow() {
    return (
      <div className="reserves-action-buttons-container row">
        <div className="col-3 actions-col">
          {this.displayRefreshReservesButton()}
          {this.displayBackToReservesListButton()}
          {this.displayCreateReserveButton()}
        </div>
        <div className="col-6 search-bar-col">{this.displaySearchBar()}</div>
        <div className="col-3 actions-col"></div>
      </div>
    );
  }

  renderContentView() {
    switch (this.state.contentView) {
      case "createReserveForm":
        return this.renderCreateReserveForm();
      case "selectedReserveView":
        return this.renderSelectedDataView();
      default:
        return this.renderReservesList();
    }
  }

  render() {
    return (
      <div className="main-content-container">
        <div className="content-header-container">
          <div className="content-title">
            <h1>Reserves</h1>
            {this.displayActionsRow()}
          </div>
        </div>
        {this.renderContentView()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  web3: state.web3,
});

export default connect(mapStateToProps, null)(Reserves);
