import React from "react";
import { connect } from "react-redux";
import { PleaseConnectWallet } from "../../../components/PleaseConnectWallet";
import Button from "../../../components/Common/Button";
import Input from "../../../components/Common/Input";
import CoreStakingManagerService from "../../../services/CoreStakingManagerService";
import conversionUtils from "../../../utils/conversion";
import toastUtils from "../../../utils/toasts";
import "./index.css";

class Staking extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      approveStakeButtonLabel: "Approve",
      softBalance: "0",
      totalTokensStaked: "0",
      userDeposit: "0",
      userEarnedRewards: "0",
      stakeUnstakeAmount: 0,
    };

    this.coreStakingManagerService = new CoreStakingManagerService(
      props.web3.provider
    );

    this.fetchCurrentStakingState = this.fetchCurrentStakingState.bind(this);
    this.handleApproveStake = this.handleApproveStake.bind(this);
    this.handleUnstake = this.handleUnstake.bind(this);
    this.handleClaimStakingRewards = this.handleClaimStakingRewards.bind(this);
    this.renderStakeActionButtons = this.renderStakeActionButtons.bind(this);
    this.mintSOFTForUser = this.mintSOFTForUser.bind(this);
  }

  async componentDidMount() {
    await this.fetchCurrentStakingState();
  }

  async fetchCurrentStakingState() {
    this.setState({
      softBalance: await this.coreStakingManagerService.totalGovTokenBalance(),
      totalTokensStaked:
        await this.coreStakingManagerService.totalTokensStaked(),
      userDeposit: await this.coreStakingManagerService.userDeposit(),
      userEarnedRewards:
        await this.coreStakingManagerService.userEarnedRewards(),
    });
  }

  async handleApproveStake(evt) {
    try {
      evt.preventDefault();

      const allowance = await this.coreStakingManagerService.allowance();
      if (this.state.approveStakeButtonLabel === "Approve" && allowance.eq(0)) {
        await this.coreStakingManagerService.approve(
          this.state.stakeUnstakeAmount
        );
        return this.setState({ approveStakeButtonLabel: "Stake" });
      }

      await this.coreStakingManagerService.stake(this.state.stakeUnstakeAmount);
      await this.fetchCurrentStakingState();
    } catch (error) {
      console.log("handleApproveStake() error", error);
      toastUtils.displayToastMessage(
        "error",
        "Failed to handle approve and/or stake operation."
      );
    }
  }

  async handleClaimStakingRewards(evt) {
    try {
      evt.preventDefault();

      await this.coreStakingManagerService.claimStakingRewards();
      await this.fetchCurrentStakingState();
    } catch (error) {
      console.log("handleClaimStakingRewards() error", error);
      toastUtils.displayToastMessage(
        "error",
        "Failed to claim staking rewards."
      );
    }
  }

  async handleUnstake(evt) {
    try {
      evt.preventDefault();

      await this.coreStakingManagerService.unstake(
        this.state.stakeUnstakeAmount
      );
      await this.fetchCurrentStakingState();
    } catch (error) {
      console.log("handleUnstake() error", error);
      toastUtils.displayToastMessage(
        "error",
        "Failed to handle unstake operation."
      );
    }
  }

  renderStakeActionButtons() {
    const renderUnstakeButton = Number(this.state.userDeposit) > 0;
    return (
      <div className="staking-form-actions-container">
        <Button onClick={this.handleApproveStake}>
          {this.state.approveStakeButtonLabel}
        </Button>
        {renderUnstakeButton && (
          <Button onClick={this.handleUnstake}>Unstake</Button>
        )}
      </div>
    );
  }

  async mintSOFTForUser(evt) {
    try {
      evt.preventDefault();
      await this.coreStakingManagerService.mintSOFTForUser();
      await this.fetchCurrentStakingState();
    } catch (error) {
      console.log("mintSOFTForUser() error", error);
      let message = "Failed to mint SOFT tokens.";
      if (error.reason) {
        message = error.reason;
      }
      toastUtils.displayToastMessage("error", message);
    }
  }

  render() {
    if (this.props.web3.provider === null || this.props.web3.account === null) {
      return <PleaseConnectWallet />;
    }
    return (
      <div className="main-content-container">
        <div className="staking-header-container row">
          <div className="staking-header-title col-2">
            <h1>Stake</h1>
          </div>
          <div className="staking-header-actions col-10">
            <Button
              className="staking-claim-button"
              onClick={this.mintSOFTForUser}
            >
              Faucet
            </Button>
            <Button
              className="staking-claim-button"
              onClick={this.handleClaimStakingRewards}
            >
              Claim
            </Button>
          </div>
        </div>
        <div className="staking-info-container">
          <p className="staking-info-label">
            <strong>Total SOFT staked: </strong>
            {conversionUtils.trimString(this.state.totalTokensStaked, 17)} SOFT
          </p>
          <p className="staking-info-label">
            <strong>Your SOFT balance: </strong>
            {conversionUtils.trimString(this.state.softBalance, 17)} SOFT
          </p>
          <p className="staking-info-label">
            <strong>Your staked balance: </strong>
            {conversionUtils.trimString(this.state.userDeposit, 17)} SOFT
          </p>
          <p className="staking-info-label">
            <strong>Your earned rewards (updates periodically): </strong>
            {conversionUtils.trimString(this.state.userEarnedRewards, 17)} SOFT
          </p>
        </div>
        <form className="staking-form">
          <div className="staking-form-input-container">
            <label className="staking-info-label">
              <strong>Total amount to stake/unstake:</strong>
            </label>
            <Input
              type="number"
              placeholder="10000000"
              className="staking-form-input"
              value={this.state.stakeUnstakeAmount}
              onChange={(e) =>
                this.setState({ stakeUnstakeAmount: e.target.value })
              }
            ></Input>
            SOFT
          </div>
          {this.renderStakeActionButtons()}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  web3: state.web3,
});

export default connect(mapStateToProps, null)(Staking);
