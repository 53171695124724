import React from "react";
import { connect } from "react-redux";
import Button from "../../../components/Common/Button";
import Input from "../../../components/Common/Input";
import { PleaseConnectWallet } from "../../../components/PleaseConnectWallet";
import CoreLockManagerService from "../../../services/CoreLockManagerService";
import conversionUtils from "../../../utils/conversion";
import toastUtils from "../../../utils/toasts";
import "./index.css";

class Lock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      softBalance: "0",
      totalTokensLocked: "0",
      userLockedBalance: "0",
      userLockEndTimestamp: "Lock tokens first",
      softToLockAmount: 0,
      lockTimeInWeeks: 0,
      approveLockButtonLabel: "Approve",
      updatedSoftToLockAmount: 0,
      updatedLockTimeInWeeks: 0,
      approveIncreaseLockButtonLabel: "Approve",
    };

    this.coreLockManagerService = new CoreLockManagerService(
      props.web3.provider
    );

    this.handleLockingTokens = this.handleLockingTokens.bind(this);
    this.handleUpdateLockInfo = this.handleUpdateLockInfo.bind(this);
    this.canUnlockTokens = this.canUnlockTokens.bind(this);
    this.handleUnlock = this.handleUnlock.bind(this);
    this.fetchCurrentLockState = this.fetchCurrentLockState.bind(this);
  }

  async componentDidMount() {
    await this.fetchCurrentLockState();
  }

  async fetchCurrentLockState() {
    const lockedDeposit =
      await this.coreLockManagerService.fetchLockedDeposit();
    this.setState({
      softBalance: await this.coreLockManagerService.totalGovTokenBalance(),
      totalTokensLocked: await this.coreLockManagerService.totalTokensLocked(),
      userLockedBalance: lockedDeposit.balance,
      userLockEndTimestamp: lockedDeposit.endTimestamp,
    });
  }

  async handleLockingTokens(evt) {
    try {
      evt.preventDefault();

      const allowance = await this.coreLockManagerService.allowance();
      if (this.state.approveLockButtonLabel === "Approve" && allowance.eq(0)) {
        await this.coreLockManagerService.approve(this.state.softToLockAmount);
        return this.setState({ approveLockButtonLabel: "Lock" });
      }
      if (Number(this.state.userLockedBalance) > 0) {
        throw new Error("Lock exists");
      }
      await this.coreLockManagerService.createLock(
        this.state.softToLockAmount,
        this.state.lockTimeInWeeks
      );
      await this.fetchCurrentLockState();
    } catch (error) {
      console.log("handleLockingTokens() error", error);
      toastUtils.displayToastMessage(
        "error",
        "Failed to handle approve and/or lock operation."
      );
    }
  }

  async handleUpdateLockInfo(evt) {
    try {
      evt.preventDefault();

      const { updatedSoftToLockAmount, updatedLockTimeInWeeks } = this.state;

      if (updatedSoftToLockAmount === 0 && updatedLockTimeInWeeks === 0) {
        throw new Error("Please enter values for updating your lock time.");
      }
      if (updatedSoftToLockAmount > 0) {
        const allowance = await this.coreLockManagerService.allowance();
        if (
          this.state.approveIncreaseLockButtonLabel === "Approve" &&
          allowance.eq(0)
        ) {
          await this.coreLockManagerService.approve(
            this.state.updatedSoftToLockAmount
          );
          return this.setState({ approveIncreaseLockButtonLabel: "Update" });
        }
      }
      if (updatedSoftToLockAmount > 0 && updatedLockTimeInWeeks > 0) {
        await this.coreLockManagerService.increaseLockBalanceAndTime(
          updatedSoftToLockAmount,
          updatedLockTimeInWeeks
        );
      } else if (updatedSoftToLockAmount > 0) {
        await this.coreLockManagerService.increaseLockBalance(
          updatedSoftToLockAmount
        );
      } else {
        // Due to the check above, we know updatedLockTimeInWeeks > 0
        await this.coreLockManagerService.increaseLockTime(
          updatedLockTimeInWeeks
        );
      }

      await this.fetchCurrentLockState();
    } catch (error) {
      console.log("handleUpdateLockInfo() error", error);
      toastUtils.displayToastMessage(
        "error",
        "Failed to handle approve and/or lock operation."
      );
    }
  }

  canUnlockTokens() {
    if (this.state.userLockEndTimestamp === "Lock tokens first") {
      return false;
    }
    return Date.now() > new Date(this.state.userLockEndTimestamp).getTime();
  }

  async handleUnlock(evt) {
    try {
      evt.preventDefault();
      if (this.canUnlockTokens()) {
        throw new Error("Tokens can only be unlocked on/after the unlock date");
      }
      await this.coreLockManagerService.claimLockedDeposit();
      await this.fetchCurrentLockState();
    } catch (error) {
      console.log("handleUnlock() error", error);
      toastUtils.displayToastMessage(
        "error",
        "Failed to handle unlock operation."
      );
    }
  }

  render() {
    if (this.props.web3.provider === null || this.props.web3.account === null) {
      return <PleaseConnectWallet />;
    }
    return (
      <div className="main-content-container">
        <div className="lock-header-container row">
          <div className="lock-title-container col-2">
            <h1>Lock</h1>
          </div>
          <div className="lock-header-actions col-10">
            {this.canUnlockTokens() && (
              <Button className="lock-claim-butto" onClick={this.handleUnlock}>
                Unlock
              </Button>
            )}
          </div>
        </div>
        <div>
          <div className="staking-info-container">
            <p className="staking-info-label">
              <strong>Total SOFT locked: </strong>
              {conversionUtils.trimString(
                this.state.totalTokensLocked,
                17
              )}{" "}
              SOFT
            </p>
            <p className="staking-info-label">
              <strong>Your SOFT balance: </strong>
              {conversionUtils.trimString(this.state.softBalance, 17)} SOFT
            </p>
            <p className="staking-info-label">
              <strong>Your locked balance: </strong>
              {conversionUtils.trimString(
                this.state.userLockedBalance,
                17
              )}{" "}
              SOFT
            </p>
            <p className="staking-info-label">
              <strong>Unlock date: </strong>
              {this.state.userLockEndTimestamp}
            </p>
          </div>
          <form className="staking-form">
            <h2 className="staking-form-sub-header">Create | Update</h2>
            <div className="staking-form-input-container">
              <label className="staking-info-label">
                <strong>Total amount to lock:</strong>
              </label>
              <Input
                type="number"
                placeholder="10000000"
                className="staking-form-input"
                value={this.state.softToLockAmount}
                onChange={(e) =>
                  this.setState({ softToLockAmount: e.target.value })
                }
              ></Input>
              SOFT
            </div>
            <div className="staking-form-input-container">
              <label className="staking-info-label">
                <strong>Total weeks to lock [min: 5, max: 10]:</strong>
              </label>
              <Input
                type="number"
                placeholder="10"
                className="staking-form-input"
                value={this.state.lockTimeInWeeks}
                onChange={(e) =>
                  this.setState({ lockTimeInWeeks: e.target.value })
                }
              ></Input>
              weeks
            </div>
            <div className="staking-form-actions-container">
              <Button onClick={this.handleLockingTokens}>
                {this.state.approveLockButtonLabel}
              </Button>
            </div>
            <div className="staking-form-input-container">
              <label className="staking-info-label">
                <strong>Increase lock amount by:</strong>
              </label>
              <Input
                type="number"
                placeholder="10000000"
                className="staking-form-input"
                value={this.state.updatedSoftToLockAmount}
                onChange={(e) =>
                  this.setState({ updatedSoftToLockAmount: e.target.value })
                }
              ></Input>
              SOFT
            </div>
            <div className="staking-form-input-container">
              <label className="staking-info-label">
                <strong>Increase lock time to [max: 10]:</strong>
              </label>
              <Input
                type="number"
                placeholder="10"
                className="staking-form-input"
                value={this.state.updatedLockTimeInWeeks}
                onChange={(e) =>
                  this.setState({ updatedLockTimeInWeeks: e.target.value })
                }
              ></Input>
              weeks
            </div>
            <div className="staking-form-actions-container">
              <Button onClick={this.handleUpdateLockInfo}>
                {this.state.approveIncreaseLockButtonLabel}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  web3: state.web3,
});

export default connect(mapStateToProps, null)(Lock);
