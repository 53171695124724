import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import Router from "./components/Router";
import { store } from "./redux/store";
import "./index.css";

// Heap Analytics
const heapAppID =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_HEAP_APP_ID_PROD
    : process.env.REACT_APP_HEAP_APP_ID_DEV;
window.heap.load(heapAppID);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Router />
  </Provider>
);
