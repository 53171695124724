import { ethers } from "ethers";
import { toast } from "react-toastify";
import CoreReserve from "../../contracts/CoreReserve.sol/CoreReserve.json";
import ERC20 from "../../contracts/dependencies/openzeppelin/ERC20.sol/ERC20.json";
import conversionUtils from "../../utils/conversion";
import toastUtils from "../../utils/toasts";

class CoreReserveService {
  constructor({ provider, reserveAddress, chainCurrencySymbol }) {
    this.provider = provider;
    this.reserveAddress = reserveAddress;
    this.chainCurrencySymbol = chainCurrencySymbol;
    this.contract = new ethers.Contract(
      reserveAddress,
      CoreReserve.abi,
      this.provider.getSigner()
    );
    console.log("CoreReserve contract instance:", this.contract);

    this.checkAllowanceFor = this.checkAllowanceFor.bind(this);
    this.approveTransferFor = this.approveTransferFor.bind(this);
    this.fetchReserveData = this.fetchReserveData.bind(this);
  }

  displayTXSubmittedMessage() {
    toastUtils.displayToastMessage(
      "info",
      "Your transaction has been submitted. Refresh to see updates once it is no longer processing",
      {
        autoClose: 10000,
        position: toast.POSITION.BOTTOM_LEFT,
      }
    );
  }

  async checkAllowanceFor(reserveToken) {
    const erc20Contract = new ethers.Contract(
      reserveToken,
      ERC20.abi,
      this.provider.getSigner()
    );
    const signerAddress = await this.provider.getSigner().getAddress();
    return erc20Contract.allowance(this.reserveAddress, signerAddress);
  }

  async approveTransferFor(reserveToken, amount) {
    const erc20Contract = new ethers.Contract(
      reserveToken,
      ERC20.abi,
      this.provider.getSigner()
    );
    const decimals = await erc20Contract.decimals();

    amount = ethers.utils.parseUnits(`${amount}`, decimals);

    const signerAddress = await this.provider.getSigner().getAddress();
    const allowance = await erc20Contract.allowance(
      this.reserveAddress,
      signerAddress
    );
    console.log("allowance:", allowance);
    if (allowance.gte(amount)) {
      return;
    }

    const tx = await erc20Contract.approve(this.reserveAddress, amount);

    toastUtils.displayToastMessage(
      "info",
      "Processing transaction...wait until button says 'Deposit'",
      {
        autoClose: 10000,
        position: toast.POSITION.BOTTOM_LEFT,
      }
    );

    await tx.wait();
  }

  async fetchReserveData() {
    const reserveData = await this.contract.reserveData();
    console.log(`Reserve ${this.reserveAddress} data:`, reserveData);
    let depositAmount = 0;
    let reserveTVL;
    let tokenSymbol;
    let signerAddress = await this.provider.getSigner().getAddress();

    if (reserveData.isNativeTokenReserve) {
      try {
        depositAmount = await this.contract.nativeDepositAmountFor(
          signerAddress
        );
        depositAmount = conversionUtils.weiToEther(depositAmount);
      } catch (error) {
        console.log("fetchReserveData nativeDepositAmountFor error:", error);
      } finally {
        reserveTVL = await this.provider.getBalance(this.reserveAddress);
        reserveTVL = ethers.utils.formatEther(reserveTVL);
        tokenSymbol = this.chainCurrencySymbol;
      }
    } else {
      try {
        depositAmount = await this.contract.erc20DepositAmountFor(
          signerAddress
        );
      } catch (error) {
        console.log("fetchReserveData erc20DepositAmountFor error:", error);
        if (error.reason) console.log("reason:", error.reason);
      }

      const erc20Contract = new ethers.Contract(
        reserveData.reserveToken,
        ERC20.abi,
        this.provider.getSigner()
      );
      const decimals = await erc20Contract.decimals();
      reserveTVL = await erc20Contract.balanceOf(this.reserveAddress);
      reserveTVL = ethers.utils.formatUnits(reserveTVL, decimals);
      tokenSymbol = await erc20Contract.symbol();
      if (depositAmount !== 0 && ethers.BigNumber.isBigNumber(depositAmount)) {
        depositAmount = ethers.utils.formatUnits(depositAmount, decimals);
      }
    }

    return {
      reserveTVL,
      tokenSymbol,
      depositAmount,
      address: this.reserveAddress,
      creator: reserveData.creator,
      reserveType: reserveData.reserveType,
      isNativeTokenReserve: reserveData.isNativeTokenReserve,
      reserveToken: reserveData.reserveToken,
    };
  }

  async fetchLendingYieldDepositAmount(
    isNativeTokenReserve,
    reserveToken,
    lendingYieldManagerAddress,
    lendingInterestBearingTokenAddress
  ) {
    const signer = await this.provider.getSigner();
    const signerAddress = await signer.getAddress();
    const erc20Contract = new ethers.Contract(
      lendingInterestBearingTokenAddress,
      ERC20.abi,
      signer
    );
    const decimals = await erc20Contract.decimals();
    let lendingDepositAmount = await this.contract.lendingMarketDeposits(
      signerAddress,
      lendingYieldManagerAddress,
      isNativeTokenReserve ? ethers.constants.AddressZero : reserveToken
    );
    console.log("lendingDepositAmount:", lendingDepositAmount);

    return ethers.utils.formatUnits(lendingDepositAmount, decimals);
  }

  async deposit(isNativeTokenReserve, reserveToken, depositAmount) {
    const signer = this.provider.getSigner();
    const signerAddress = await signer.getAddress();
    let tx;

    if (isNativeTokenReserve) {
      depositAmount = ethers.utils.parseUnits(`${depositAmount}`, 18);
      tx = await this.contract.deposit(depositAmount, signerAddress, {
        value: depositAmount,
      });
    } else {
      const erc20Contract = new ethers.Contract(
        reserveToken,
        ERC20.abi,
        signer
      );
      const decimals = await erc20Contract.decimals();

      depositAmount = ethers.utils.parseUnits(`${depositAmount}`, decimals);

      tx = await this.contract.deposit(depositAmount, signerAddress);
    }

    this.displayTXSubmittedMessage();

    await tx.wait();
  }

  async transferReserveDepositToLendingPool(
    isNativeTokenReserve,
    proportionOfExistingDeposit,
    lendingYieldManagerAddress
  ) {
    console.log(
      "transferReserveDepositToLendingPool:",
      isNativeTokenReserve,
      proportionOfExistingDeposit,
      lendingYieldManagerAddress
    );

    if (proportionOfExistingDeposit > 50 || proportionOfExistingDeposit < 1) {
      throw new Error("Proportion out of range: [1, 50]");
    }
    if (lendingYieldManagerAddress === ethers.constants.AddressZero) {
      throw new Error("Invalid LendingYieldManager address");
    }

    // To match value denomination for percentages in Solidity.
    proportionOfExistingDeposit = proportionOfExistingDeposit * 100;

    const tx = await this.contract.transferReserveDepositToLendingPool(
      isNativeTokenReserve,
      proportionOfExistingDeposit,
      lendingYieldManagerAddress
    );

    this.displayTXSubmittedMessage();

    await tx.wait();
  }

  async transferFromLendingPoolToReserveDeposit({
    isNativeTokenReserve,
    withdrawAmount,
    lendingYieldManagerAddress,
    lendingInterestBearingTokenAddress,
  }) {
    console.log(
      "transferFromLendingPoolToReserveDeposit:",
      isNativeTokenReserve,
      withdrawAmount,
      lendingYieldManagerAddress,
      lendingInterestBearingTokenAddress
    );

    if (withdrawAmount <= 0) {
      throw new Error("Invalid withdraw amount");
    }
    if (lendingYieldManagerAddress === ethers.constants.AddressZero) {
      throw new Error("Invalid LendingYieldManager address");
    }
    if (lendingInterestBearingTokenAddress === ethers.constants.AddressZero) {
      throw new Error("Invalid Lending Pool Interest Bearing Token address");
    }

    const erc20Contract = new ethers.Contract(
      lendingInterestBearingTokenAddress,
      ERC20.abi,
      this.provider.getSigner()
    );
    const decimals = await erc20Contract.decimals();
    console.log("decimals:", decimals);

    withdrawAmount = ethers.utils.parseUnits(withdrawAmount, decimals);
    console.log("withdrawAmount:", withdrawAmount);

    const tx = await this.contract.transferFromLendingPoolToReserveDeposit(
      isNativeTokenReserve,
      withdrawAmount,
      lendingYieldManagerAddress,
      lendingInterestBearingTokenAddress
    );

    this.displayTXSubmittedMessage();

    await tx.wait();
  }

  async withdraw({
    isNativeTokenReserve,
    chainID,
    reserveToken,
    depositAmount,
    withdrawAmount,
  }) {
    let decimals = conversionUtils.decimalsForChainID(chainID);
    if (!isNativeTokenReserve) {
      const erc20Contract = new ethers.Contract(
        reserveToken,
        ERC20.abi,
        this.provider.getSigner()
      );
      decimals = await erc20Contract.decimals();
    }

    withdrawAmount = ethers.utils.parseUnits(`${withdrawAmount}`, decimals);
    depositAmount = ethers.utils.parseUnits(`${depositAmount}`, decimals);

    console.log(withdrawAmount, depositAmount);

    if (withdrawAmount.gt(depositAmount)) {
      throw new Error(
        "Withdraw amount entered is greater than your deposit amount."
      );
    }

    const tx = await this.contract.withdraw(withdrawAmount);

    toastUtils.displayToastMessage(
      "success",
      "Your transaction has been submitted. Refresh to see updates once it is no longer processing"
    );

    await tx.wait();
  }
}

export default CoreReserveService;
