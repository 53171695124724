export const switchNetwork = (chainId = 1) => {
  window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [
      {
        chainId,
      },
    ],
  });
};
