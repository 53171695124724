import React from "react";
import Button from "../Common/Button";
import Input from "../Common/Input";
import toastUtils from "../../utils/toasts";
import "./index.css";
import { ethers } from "ethers";

export const ReserveSearchForm = (props) => {
  let searchText = "";

  const fetchETHReserve = (evt) => {
    evt.preventDefault();

    toastUtils.displayToastMessage("info", "Loading...");

    props.onUpdateReservesList({
      reserveToken: ethers.constants.AddressZero,
    });

    searchText = "";
  };

  const fetchCoreReserve = async (evt) => {
    evt.preventDefault();

    toastUtils.displayToastMessage("info", "Loading...");

    if (searchText === "") {
      return props.onUpdateReservesList(null);
    }

    const coreReserves =
      await props.coreReserveFactoryGraphQLService.fetchCoreReserveUsingReserveToken(
        searchText.trim()
      );

    if (coreReserves.length > 0) {
      return props.onUpdateReservesList(coreReserves[0]);
    }

    toastUtils.displayToastMessage(
      "error",
      `A reserve with the given reserve token address was not found: ${searchText}`
    );

    searchText = "";
  };

  return (
    <div className="search-input-container">
      <Button className="search-button" onClick={fetchETHReserve}>
        ETH
      </Button>
      <Input
        type="text"
        className="search-input"
        placeholder="Reserve token address: 0x1x2Y3z..."
        onChange={(e) => (searchText = e.target.value)}
      ></Input>
      <Button className="search-button" onClick={fetchCoreReserve}>
        Search
      </Button>
    </div>
  );
};
