import {
  INIT_WEB3,
  CLEAR_WEB3,
  CLEAR_PROVIDER,
  CLEAR_ACCOUNT,
  SET_PROVIDER,
  SET_ACCOUNT,
} from '../constants';

const initalState = {
  account: null,
  provider: null,
  chainId: null,
  chainName: null,
};

const web3Reducer = (state = initalState, action) => {
  switch (action.type) {
    case INIT_WEB3: {
      return {
        account: action.account,
        provider: action.provider,
        chainId: action.chainId,
        chainName: action.chainName,
      };
    }
    case CLEAR_WEB3: {
      return {
        account: null,
        provider: null,
      };
    }
    case SET_PROVIDER: {
      return {
        ...state,
        provider: action.provider,
      };
    }
    case CLEAR_PROVIDER: {
      return {
        ...state,
        provider: null,
      };
    }
    case SET_ACCOUNT: {
      return {
        ...state,
        account: action.account,
      };
    }
    case CLEAR_ACCOUNT: {
      return {
        ...state,
        account: null,
      };
    }
    default:
      return state;
  }
};

export default web3Reducer;
