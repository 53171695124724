import {
  aaveLendingYieldManagerAddress,
  compoundLendingYieldManagerAddress,
} from "./contracts";

const GOERLI_CHAIN_ID = 5;

const config = {
  availableChains: [GOERLI_CHAIN_ID],
  lendingYieldManagers: [
    aaveLendingYieldManagerAddress,
    compoundLendingYieldManagerAddress,
  ],
};

export default config;
