export const waitlistV0Address = "0xeB4a5A11125A53fB92D8E8C9EEA353A2Abe560Ba";
export const coreFlashLoanParamsAddress =
  "0x2338e0aE10D1f2Ec24532aceb33b90De8B80cf87";
export const coreTreasuryAddress = "0x30C66bDe20C64046243F23Eca0024c7FdE3f684F";
export const coreRewardsDistributorAddress =
  "0xE17745365AcEdBe68BC93f08EF6B221055BF867a";
export const coreReserveFactoryAddress =
  "0xd018FC4Aa3a373e8B0D69986d5874A975D200437";
export const coreFundReserveAddress =
  "0xc01D9ba0385F315c6e1aE4DEA22618D133476eFc";
export const ownerAddress = "0x73749f9545231e682Fc6B2CE13914E73F004a463";
export const ownedNativeSnipingReserveAddress =
  "0xb8A56229D2e6B9a5fE120ea88Ed8bE26057c2a7e";
export const aaveLendingYieldManagerAddress =
  "0x71520c1F7F7fcb0CFE0D9931B24e60Abc6398B2E";
export const compoundLendingYieldManagerAddress =
  "0xe963cbDCd63F62356a381fe597e3c48c374b6F3a";
export const softTokenAddress = "0x7752234df5bb4abad53d40dd3cf91f4f6edde719";
export const coreStakingManagerAddress =
  "0xDaEd4C8ee1665c9D71046cC6cb9b0bA3bc3725b5";
export const softTokenFaucetAddress =
  "0x938663Ba1Bf5adFF6FE22B546ADF905ee3DB065A";
export const coreLockManagerAddress =
  "0x46629DB17F39BF1290E19f3840e385AF017D9F44";
