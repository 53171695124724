import { ethers } from "ethers";
import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import Button from "../../../../components/Common/Button";
import Input from "../../../../components/Common/Input";
import CoreReserveService from "../../../../services/CoreReserveService";
import conversionUtils from "../../../../utils/conversion";
import toastUtils from "../../../../utils/toasts";
import "./index.css";

Modal.setAppElement("#root");

class Reserve extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contentView: "reserveData",
      depositModalApproveDepositLabel: "Approve",
      RenderDepositModal: false,
      RenderLendModal: false,
      RenderVoteModal: false,
      RenderWithdrawModal: false,
      depositWithdrawAmount: "",
      lendingOptionForm: "LendFunds",
      proportionOfExistingDepositToLend: 0,
      lendingWithdrawAmount: 0,
      lendingDepositAmount: undefined,
      lendingYieldManagerAddress: "",
      lendingInterestBearingTokenAddress: "",
      lendingYieldManagerAddressesLink:
        "https://docs.softlink.finance/developers/addresses",
    };

    this.coreReserveService = new CoreReserveService({
      provider: props.web3.provider,
      reserveAddress: props.selectedReserve.address,
      chainCurrencySymbol: props.selectedReserve.tokenSymbol,
    });

    this.renderContentView = this.renderContentView.bind(this);
    this.renderReserveData = this.renderReserveData.bind(this);
    this.renderReserveDataHeader = this.renderReserveDataHeader.bind(this);
    this.renderReserveDataContent = this.renderReserveDataContent.bind(this);
    this.renderActionModal = this.renderActionModal.bind(this);
    this.renderDepositModal = this.renderDepositModal.bind(this);
    this.handleDeposit = this.handleDeposit.bind(this);
    this.renderLendModal = this.renderLendModal.bind(this);
    this.renderLendOptionForm = this.renderLendOptionForm.bind(this);
    this.updateLendingYieldAddresses =
      this.updateLendingYieldAddresses.bind(this);
    this.updateLendOptionForm = this.updateLendOptionForm.bind(this);
    this.renderVoteModal = this.renderVoteModal.bind(this);
    this.renderWithdrawModal = this.renderWithdrawModal.bind(this);
    this.lendOrWithdraw = this.lendOrWithdraw.bind(this);
  }

  async handleDeposit(evt) {
    try {
      if (evt) evt.preventDefault();
      if (this.state.depositModalApproveDepositLabel === "Approve") {
        if (!this.props.selectedReserve.isNativeTokenReserve) {
          await this.coreReserveService.approveTransferFor(
            this.props.selectedReserve.reserveToken,
            Number(this.state.depositWithdrawAmount)
          );
        }
        this.setState({ depositModalApproveDepositLabel: "Deposit" });
      } else {
        await this.coreReserveService.deposit(
          this.props.selectedReserve.isNativeTokenReserve,
          this.props.selectedReserve.reserveToken,
          Number(this.state.depositWithdrawAmount)
        );
        this.renderActionModal(undefined, "RenderDepositModal", false);
      }
    } catch (error) {
      console.log("Error handle deposit:", error);

      let message =
        "Failed to deposit into a reserve. Please review your values and/or the console for errors";
      if (error.message && typeof error.message === "string") {
        message = error.message;
      }
      if (error.reason && typeof error.reason === "string") {
        message = error.reason;
      }

      return toastUtils.displayToastMessage("error", message);
    }
  }

  async lendOrWithdraw(evt, action, actionView) {
    try {
      if (evt) evt.preventDefault();
      if (action === 0) {
        await this.coreReserveService.transferReserveDepositToLendingPool(
          this.props.selectedReserve.isNativeTokenReserve,
          this.state.proportionOfExistingDepositToLend,
          this.state.lendingYieldManagerAddress
        );
      } else if (action === 1) {
        await this.coreReserveService.transferFromLendingPoolToReserveDeposit({
          isNativeTokenReserve: this.props.selectedReserve.isNativeTokenReserve,
          withdrawAmount: this.state.lendingWithdrawAmount,
          lendingYieldManagerAddress: this.state.lendingYieldManagerAddress,
          lendingInterestBearingTokenAddress:
            this.state.lendingInterestBearingTokenAddress,
        });
      } else {
        await this.coreReserveService.withdraw({
          isNativeTokenReserve: this.props.selectedReserve.isNativeTokenReserve,
          chainId: this.props.selectedReserve.chainId,
          reserveToken: this.props.selectedReserve.reserveToken,
          depositAmount: this.props.selectedReserve.depositAmount,
          withdrawAmount: Number(this.state.depositWithdrawAmount),
        });
      }
      this.renderActionModal(undefined, actionView, false);
    } catch (error) {
      console.log("lendOrWithdraw error:", error);

      let message = `Please try again and/or check the console logs`;
      if (error.message && typeof error.message === "string") {
        message = error.message;
      }

      toastUtils.displayToastMessage("error", message);
    }
  }

  renderDepositModal() {
    return (
      <Modal
        isOpen={this.state.RenderDepositModal}
        className="reserve-deposit-modal-container"
        onRequestClose={() =>
          this.renderActionModal(undefined, "RenderDepositModal", false)
        }
      >
        <form className="reserve-deposit-form">
          <div className="reserve-deposit-form-input-container">
            <div className="deposit-form-label-container">
              <label>Deposit Amount:</label>
            </div>
            <Input
              className="reserve-deposit-form-input"
              type="number"
              placeholder="1234"
              value={this.state.depositWithdrawAmount}
              onChange={(e) =>
                this.setState({ depositWithdrawAmount: e.target.value })
              }
            ></Input>
            <span> {this.props.selectedReserve.tokenSymbol}</span>
          </div>
          <div className="reserve-deposit-form-input-container">
            <Button
              onClick={(e) =>
                this.renderActionModal(e, "RenderDepositModal", false)
              }
            >
              Cancel
            </Button>
            <Button
              className="selected-reserve-form-button"
              onClick={this.handleDeposit}
            >
              {this.state.depositModalApproveDepositLabel}
            </Button>
          </div>
        </form>
      </Modal>
    );
  }

  updateLendOptionForm(evt, lendingOptionForm) {
    if (evt) evt.preventDefault();
    this.setState({ lendingOptionForm });
  }

  updateLendingYieldAddresses(e, lendingYieldAddressKey) {
    this.setState({ [lendingYieldAddressKey]: e.target.value }, async () => {
      const { lendingYieldManagerAddress, lendingInterestBearingTokenAddress } =
        this.state;
      try {
        const lendingDepositAmount =
          await this.coreReserveService.fetchLendingYieldDepositAmount(
            this.props.selectedReserve.isNativeTokenReserve,
            this.props.selectedReserve.reserveToken,
            ethers.utils.getAddress(lendingYieldManagerAddress),
            ethers.utils.getAddress(lendingInterestBearingTokenAddress)
          );
        this.setState({ lendingDepositAmount });
      } catch (error) {
        console.log("fetchLendingYieldDepositAmount() error:", error);
        this.setState({ lendingDepositAmount: null });
      }
    });
  }

  renderLendOptionForm() {
    if (this.state.lendingOptionForm === "LendFunds") {
      return (
        <div>
          <div>
            <div className="deposit-form-label-container">
              <label>
                Proportion of Exisiting Deposit to Lend (only up to 50% at
                first):
              </label>
            </div>
            <Input
              className="reserve-deposit-form-input"
              type="number"
              placeholder="1234"
              value={this.state.proportionOfExistingDepositToLend}
              onChange={(e) =>
                this.setState({
                  proportionOfExistingDepositToLend: e.target.value,
                })
              }
            ></Input>
            <span> %</span>
            <div className="deposit-form-label-container">
              <label>
                LendingYieldManager Address (
                <a
                  href={this.state.lendingYieldManagerAddressesLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  addresses
                </a>
                ):
              </label>
            </div>
            <Input
              className="reserve-deposit-form-input"
              type="text"
              placeholder="0xEeee...."
              value={this.state.lendingYieldManagerAddress}
              onChange={(e) =>
                this.setState({ lendingYieldManagerAddress: e.target.value })
              }
            ></Input>
          </div>
          <div className="reserve-deposit-form-input-container">
            <Button
              onClick={(e) =>
                this.renderActionModal(e, "RenderLendModal", false)
              }
            >
              Cancel
            </Button>
            <Button
              className="selected-reserve-form-button"
              onClick={(e) => this.lendOrWithdraw(e, 0, "RenderLendModal")}
            >
              Lend
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div>
            <div className="deposit-form-label-container">
              <label>
                Lending Pool Interest Bearing Token Address (
                <a
                  href={this.state.lendingYieldManagerAddressesLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  addresses
                </a>
                ):
              </label>
            </div>
            <Input
              className="reserve-deposit-form-input"
              type="text"
              placeholder="0xEeee...."
              value={this.state.lendingInterestBearingTokenAddress}
              onChange={(e) =>
                this.updateLendingYieldAddresses(
                  e,
                  "lendingInterestBearingTokenAddress"
                )
              }
            ></Input>
            <div className="deposit-form-label-container">
              <label>Withdraw from Lending Pool Amount:</label>
            </div>
            <Input
              className="reserve-deposit-form-input"
              type="number"
              placeholder="1234"
              value={this.state.lendingWithdrawAmount}
              onChange={(e) =>
                this.setState({ lendingWithdrawAmount: e.target.value })
              }
            ></Input>
            <div className="deposit-form-label-container">
              <label>
                LendingYieldManager Address (
                <a
                  href={this.state.lendingYieldManagerAddressesLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  addresses
                </a>
                ):
              </label>
            </div>
            <Input
              className="reserve-deposit-form-input"
              type="text"
              placeholder="0xEeee...."
              value={this.state.lendingYieldManagerAddress}
              onChange={(e) =>
                this.updateLendingYieldAddresses(
                  e,
                  "lendingYieldManagerAddress"
                )
              }
            ></Input>
          </div>
          <div className="reserve-deposit-form-input-container">
            <Button
              onClick={(e) =>
                this.renderActionModal(e, "RenderDepositModal", false)
              }
            >
              Cancel
            </Button>
            <Button
              className="selected-reserve-form-button"
              onClick={(e) => this.lendOrWithdraw(e, 1, "RenderLendModal")}
            >
              Remove
            </Button>
          </div>
        </div>
      );
    }
  }

  activeLendOptionButtonClass(isActive) {
    return isActive ? "active-lend-button" : "";
  }

  renderLendingDepositAmount() {
    if (this.state.lendingOptionForm === "RemoveFunds") {
      let label = "Current Lending Deposit: Fill in valid addresses below";
      if (this.state.lendingDepositAmount) {
        label = `Current Lending Deposit: ${this.state.lendingDepositAmount}`;
      }
      return <p className="reserve-lending-deposit-amount">{label}</p>;
    }
  }

  renderLendModal() {
    return (
      <Modal
        isOpen={this.state.RenderLendModal}
        className="reserve-deposit-modal-container"
        onRequestClose={() =>
          this.renderActionModal(undefined, "RenderLendModal", false)
        }
      >
        <form className="reserve-deposit-form">
          <div className="reserve-deposit-form-input-container">
            <div className="deposit-form-lend-options-container">
              {this.renderLendingDepositAmount()}
              <p>
                *AAVEV2 is used since AAVEV3 is not deployed on the ETH mainnet
                chain.
              </p>
              <Button
                className={`deposit-form-lend-button ${this.activeLendOptionButtonClass(
                  "LendFunds" === this.state.lendingOptionForm
                )}`}
                onClick={(e) => this.updateLendOptionForm(e, "LendFunds")}
              >
                Lend funds
              </Button>
              <Button
                className={`deposit-form-lend-button ${this.activeLendOptionButtonClass(
                  "RemoveFunds" === this.state.lendingOptionForm
                )}`}
                onClick={(e) => this.updateLendOptionForm(e, "RemoveFunds")}
              >
                Remove funds
              </Button>
            </div>
            {this.renderLendOptionForm()}
          </div>
        </form>
      </Modal>
    );
  }

  renderVoteModal() {
    return (
      <Modal
        isOpen={this.state.RenderVoteModal}
        className="reserve-deposit-modal-container"
        onRequestClose={() =>
          this.renderActionModal(undefined, "RenderVoteModal", false)
        }
      >
        <form className="reserve-deposit-form">
          <div className="reserve-deposit-form-input-container">
            <div className="deposit-form-label-container">
              <label>Voting service coming soon :)</label>
            </div>
          </div>
          <div className="reserve-deposit-form-input-container">
            <Button
              onClick={(e) =>
                this.renderActionModal(e, "RenderVoteModal", false)
              }
            >
              Cancel
            </Button>
            <Button
              className="selected-reserve-form-button"
              // TODO: Implement voting...
              onClick={(e) => e.preventDefault()}
            >
              Vote
            </Button>
          </div>
        </form>
      </Modal>
    );
  }

  renderWithdrawModal() {
    return (
      <Modal
        isOpen={this.state.RenderWithdrawModal}
        className="reserve-deposit-modal-container"
        onRequestClose={() =>
          this.renderActionModal(undefined, "RenderWithdrawModal", false)
        }
      >
        <form className="reserve-deposit-form">
          <div className="reserve-deposit-form-input-container">
            <div className="deposit-form-label-container">
              <label>Withdraw Amount:</label>
            </div>
            <Input
              className="reserve-deposit-form-input"
              type="number"
              placeholder="1234"
              value={this.state.depositWithdrawAmount}
              onChange={(e) =>
                this.setState({ depositWithdrawAmount: e.target.value })
              }
            ></Input>
            <span> {this.props.selectedReserve.tokenSymbol}</span>
          </div>
          <div className="reserve-deposit-form-input-container">
            <Button
              onClick={(e) =>
                this.renderActionModal(e, "RenderWithdrawModal", false)
              }
            >
              Cancel
            </Button>
            <Button
              className="selected-reserve-form-button"
              onClick={(e) => this.lendOrWithdraw(e, 2, "RenderWithdrawModal")}
            >
              Withdraw
            </Button>
          </div>
        </form>
      </Modal>
    );
  }

  renderActionModal(evt, actionView, renderView) {
    if (evt) evt.preventDefault();

    this.setState({
      [actionView]: renderView,
      depositWithdrawAmount: "",
      lendingOptionForm: "LendFunds",
      proportionOfExistingDepositToLend: 0,
      lendingWithdrawAmount: 0,
      lendingYieldManagerAddress: "",
      lendingInterestBearingTokenAddress: "",
    });
  }

  renderReserveDataHeader() {
    return (
      <div className="selected-reserve-header row">
        <div className="selected-reserve-label-container col-6">
          <h2>
            Reserve {this.props.selectedReserve.address.substring(0, 16)}...
          </h2>
        </div>
        <div className="selected-reserve-header-actions-container col-6">
          <p>
            Current Deposit:{" "}
            {conversionUtils.trimString(
              this.props.selectedReserve.depositAmount,
              7
            )}{" "}
            {this.props.selectedReserve.tokenSymbol}
          </p>
          <Button
            onClick={(e) =>
              this.renderActionModal(e, "RenderDepositModal", true)
            }
          >
            Deposit
          </Button>
          {this.props.selectedReserve.reserveType === 1 && (
            <Button
              className="selected-reserve-header-button"
              onClick={(e) =>
                this.renderActionModal(e, "RenderLendModal", true)
              }
            >
              Lend
            </Button>
          )}
          <Button
            className="selected-reserve-header-button"
            onClick={(e) => this.renderActionModal(e, "RenderVoteModal", true)}
          >
            Vote
          </Button>
          <Button
            className="selected-reserve-header-button"
            onClick={(e) =>
              this.renderActionModal(e, "RenderWithdrawModal", true)
            }
          >
            Withdraw
          </Button>
        </div>
      </div>
    );
  }

  renderReserveDataContent() {
    const chainScanLink = conversionUtils.chainIDToChainScanLink(
      this.props.web3.chainId
    );
    return (
      <div className="selected-reserve-info-container">
        <div className="selected-reserve-info-list-container">
          <p>
            <strong>TVL:</strong>{" "}
            {conversionUtils.trimString(
              this.props.selectedReserve.reserveTVL,
              16
            )}{" "}
            {this.props.selectedReserve.tokenSymbol}
          </p>
          <p>
            <strong>Is a Native Token (ETH) Reserve:</strong>{" "}
            {`${this.props.selectedReserve.isNativeTokenReserve}`}
          </p>
          <p>
            <strong>Reserve Type:</strong>{" "}
            {conversionUtils.reserveTypeValueToLabel(
              this.props.selectedReserve.reserveType
            )}
          </p>

          <p>
            <strong>Reserve Address:</strong>{" "}
            <a
              href={`${chainScanLink}/address/${this.props.selectedReserve.address}`}
              target="_blank"
              rel="noreferrer"
            >
              {this.props.selectedReserve.address}
            </a>
          </p>
          <p>
            <strong>Reserve Token:</strong>{" "}
            <a
              href={`${chainScanLink}/address/${this.props.selectedReserve.reserveToken}`}
              target="_blank"
              rel="noreferrer"
            >
              {this.props.selectedReserve.reserveToken}
            </a>
          </p>
          <p>
            <strong>Reserve Creator:</strong>{" "}
            <a
              href={`${chainScanLink}/address/${this.props.selectedReserve.creator}`}
              target="_blank"
              rel="noreferrer"
            >
              {this.props.selectedReserve.creator}
            </a>
          </p>
        </div>
      </div>
    );
  }

  renderReserveData() {
    return (
      <div className="selected-reserve-container">
        {this.renderReserveDataHeader()}
        {this.renderReserveDataContent()}
        {this.renderDepositModal()}
        {this.renderLendModal()}
        {this.renderVoteModal()}
        {this.renderWithdrawModal()}
      </div>
    );
  }

  renderContentView() {
    switch (this.state.contentView) {
      default:
        return this.renderReserveData();
    }
  }

  render() {
    return this.renderContentView();
  }
}

const mapStateToProps = (state) => ({
  web3: state.web3,
});

export default connect(mapStateToProps, null)(Reserve);
