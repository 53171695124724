import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { legacy_createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import createRootReducer from '../reducers';

export const history = createBrowserHistory();

const initialState = {};

let middleware;
if (process.env.NODE_ENV === 'production') {
  middleware = applyMiddleware(routerMiddleware(history), thunk);
} else {
  middleware = applyMiddleware(
    thunk,
    routerMiddleware(history),
    createLogger(),
  );
}

export const store = legacy_createStore(
  createRootReducer(history),
  initialState,
  compose(middleware),
);
