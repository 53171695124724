import { ethers } from "ethers";

const conversionUtils = {
  weiToEther(weiValue, fixedValue) {
    let valueInETH = ethers.utils.formatEther(weiValue);
    if (!fixedValue) {
      return valueInETH;
    }

    valueInETH = (+valueInETH).toFixed(fixedValue);

    return valueInETH;
  },
  chainIDToTokenSymbol(chainID = 1) {
    switch (chainID) {
      case 1:
        return "ETH";
      case 250:
        return "FTM";
      default:
        return "ETH";
    }
  },
  chainIDToChainScanLink(chainID = 1) {
    switch (chainID) {
      case 1:
        return "https://etherscan.io";
      case 5:
        return "https://goerli.etherscan.io";
      case 250:
        return "https://ftmscan.com";
      default:
        return "https://etherscan.io";
    }
  },
  reserveTypeValueToLabel(reserveType = 0) {
    switch (reserveType) {
      case 1:
        return "Sniping";
      default:
        return "Charity";
    }
  },
  decimalsForChainID(chainID = 1) {
    switch (chainID) {
      case 1:
        return 18;
      case 5:
        return 18;
      case 250:
        return 18;
      default:
        return 18;
    }
  },
  trimString(str, length) {
    if (isNaN(Number(str))) return "0";
    str = Number(str).toLocaleString("en-US", { minimumFractionDigits: 4 });
    if (typeof str === "number") str = String(str);
    if (length >= str.length) return str;
    return str.substring(0, length) + "...";
  },
};

export default conversionUtils;
