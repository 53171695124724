import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const APIURL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_SUBGRAPH_QUERY_URL_PROD
    : process.env.REACT_APP_SUBGRAPH_QUERY_URL_DEV;

console.log("APIURL:", APIURL);

const listRecentCoreReservesQuery = `
    query CoreReserves($offset: Int, $limit: Int){
        coreReserves(skip: $offset, first: $limit, orderBy: blockTimestamp, orderDirection: desc) {
            reserveToken
        }
    }
`;

const fetchCoreReserveUsingReserveTokenQuery = `
    query CoreReserves($reserveTokenAddress: Bytes){
        coreReserves(where: {reserveToken: $reserveTokenAddress}) {
            reserveToken
        }
    }
`;

class CoreReserveFactoryGraphQLService {
  constructor() {
    this.client = new ApolloClient({
      uri: APIURL,
      cache: new InMemoryCache(),
    });

    console.log("CoreReserveFactoryGraphQLService client:", this.client);
  }

  async listRecentCoreReserves(offset, limit) {
    try {
      const res = await this.client.query({
        query: gql(listRecentCoreReservesQuery),
        variables: {
          offset,
          limit,
        },
      });

      console.log(
        "listRecentCoreReserves offset, limit, res:",
        offset,
        limit,
        res
      );

      return res.data.coreReserves;
    } catch (error) {
      console.log("listRecentCoreReserves() error:", error);
    }
  }

  async fetchCoreReserveUsingReserveToken(reserveTokenAddress) {
    try {
      const res = await this.client.query({
        query: gql(fetchCoreReserveUsingReserveTokenQuery),
        variables: {
          reserveTokenAddress,
        },
      });

      console.log(
        "fetchCoreReserveUsingReserveToken reserveTokenAddress:",
        reserveTokenAddress,
        res
      );

      return res.data.coreReserves;
    } catch (error) {
      console.log("fetchCoreReserveUsingReserveToken() error:", error);
      return [];
    }
  }
}

export default CoreReserveFactoryGraphQLService;
