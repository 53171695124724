import CookieConsent from "react-cookie-consent";

export const removeAnalyticCookies = () => {
  console.log("removeAnalyticCookies()");
  // Remove heap analytics data
  if (window.heap) window.heap = undefined;
  // Delete all cookies from google analytics
  // Remove the default tracker.
  if (window.ga) window.ga("remove");
  if (window.gtag) {
    window.gtag("consent", "default", {
      ad_storage: "denied",
      analytics_storage: "denied",
    });
    window.gtag = null;
  }
};

export const CookiesConsentBanner = (props) => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="I accept"
      cookieName="consent-cookie"
      style={{ background: "black" }}
      buttonStyle={{ color: "#4e503b", fontSize: "15px" }}
      expires={180}
      enableDeclineButton
      onDecline={removeAnalyticCookies}
    >
      This website uses cookies to improve user experience. By using our website
      you consent to all cookies in accordance with our{" "}
      <a href="https://softlink.finance/privacy-policy.txt">Privacy Policy</a>.
    </CookieConsent>
  );
};
